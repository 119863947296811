export const API_BASE_URL = 'http://localhost:8080';
export const ACCESS_TOKEN = 'accessToken';
export const CURRENT_USER = 'currentUser';
export const SELECTED_LOCALE = 'selectedLocale';
export const DEFAULT_LOCALE = 'ja-JP';
export const PAGE_SIZE = 10;
export const PAGE_SIZE_LIST = [10, 20, 50];
export const SM_SESSION_NAME = "_study_builder_session";
export const NOTIFICATION_ID_DISPLAYED_LIST = "notificationIdDisplayedList";
export const STUDY_ID_KEY = "Study-Id";
export const STUDY_CLOSE_FLAG_KEY = "Study-Close-Flag";
export const STUDY_INFORMATION_KEY = "currentStudy_";
export const IWRS_LINK_FLAG_KEY = "iwrsLinkFlag";

export const NEW_ECS = 1;
export const COPY_ECS = 2;
export const EDIT_ECS = 3;

export const TYPE_ECS_SYSTEM_QUERY_ID = 1;
export const TYPE_ECS_BLIND_ID = 2;
export const TYPE_ECS_LINK_FORCED_ID = 3;
export const TYPE_ECS_EMAIL_ALERT_ID = 4;

export const PRIVILEGE_STATUS_ALL = -1;
export const PRIVILEGE_STATUS_ENABLE = 1;
export const PRIVILEGE_STATUS_DISABLE = 0;

export const DEFAULT_DATE_PICKER_FORMAT_LIST = ["YYYY-MM-DD", "YYYY年MM月DD日", "YYYY年M月D日"];
export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss";
export const DEFAULT_DATE_PICKER_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_PICKER_FORMAT = "HH:mm";
export const DEFAULT_TIME_UTC = 9;
export const DEFAULT_START_TIME_STRING = "00:00:00";
export const DEFAULT_END_TIME_STRING = "23:59:59";

//Quyen han dang nhap
export const USER_ROLE_SUPER_ADMIN  = "ROLE_SUPER_ADMIN";
export const USER_ROLE_ADMIN        = "ROLE_ADMIN";
export const USER_ROLE_USER         = "ROLE_USER";

//Quyen han READ && WRITE Theo Study;
export const DB_PRIVILEGE_READ_FLAG  = 0;
export const DB_PRIVILEGE_WRITE_FLAG = 1;

export const LISTMENU = {
    STUDY: 'study',
    EDCSETTING: 'edcsetting',
    ETRAINING: 'e-training',
    ROLE: 'role',
    PRIVILEGE: 'privilege',
    PRIVILEGE_SETTING: 'privilege-setting',
    CYCLE_VISIT: 'cycle-visit',
    CRFGROUP: 'crfgroup',
    SCHEDULES: 'schedules',
    PAGES: 'pages',
    PROPERTY: 'property',
    CODELIST: 'codelist',
    ESC: 'ecs',
    VERSION: 'version',
    STUDY_RELEASE:'release',
    STUDY_CLONE:'study-clone',
    USERLIST: 'user-list',
}

export const ROLE = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    ROLE_USER: 'ROLE_USER'
}

export const LIST_POPUP_ELEMENT = {
    YES: 'YES',
    NO: 'NO',
}


export const PAGE_TYPE_REG      = 1;
export const PAGE_TYPE_NORMAL   = 2;

export const BLOCK_TYPE_NORMAL = 1;
export const BLOCK_TYPE_FIXED_LINE = 2;
export const BLOCK_TYPE_LOG_LINE = 3;

export const ITEM_PROPERTY_TYPE_SCRNUM = 8;
export const ITEM_PROPERTY_TYPE_ENRNUM = 9;
export const ITEM_PROPERTY_TYPE_AUTOFILL = 1;

export const ITEM_PROPERTY_TYPE_RANDOM_KEY = 10;
export const ITEM_PROPERTY_TYPE_ARM_GENERATE = 11;
export const ITEM_PROPERTY_TYPE_IP_GENERATE = 12;
export const ITEM_PROPERTY_TYPE_STRATIFICATION = 13;
export const ITEM_PROPERTY_TYPE_HIDDEN = 14;
export const ITEM_PROPERTY_TYPE_CONSENT_DATE = 15;

export const PROPERTY_TYPE_EXCLUDE = 7;

export const DEFAULT_INPUT_MAX_LENGTH = 256;
export const ARM_INPUT_MAX_LENGTH = 100;

//
export const INPUT_ONLY_NUMERIC = 4;
export const INPUT_ONLY_YN = 1;
export const TYPE_SETTING_ALLOCATION_TYPE_OPTION = ['SB-RADIO-STATIC-STRATIFICATION', 'SB-RADIO-DYNAMIC-STRATIFICATION'];
export const TYPE_SETTING_BLIND_ARM_OPTION = ['SB-RADIO-YES-03', 'SB-RADIO-NO-03'];
export const TYPE_SETTING_EMERGENCY_FLAG_OPTION = ['SB-RADIO-YES-03', 'SB-RADIO-NO-03'];
export const TYPE_SETTING_EMERGENCY_TYPE_OPTION = ['SB-RADIO-IMMEDIATE-UBLIND', 'SB-RADIO-UNBLIND-REQUEST'];
export const TYPE_SETTING_IP_MANAGEMENT_OPTION = ['SB-RADIO-ARM', 'SB-RADIO-ARM-AND-IP-NO'];

export const DB_STUDY_CLOSE_FLAG_VALUE = 1;
export const DB_STUDY_OPEN_FLAG_VALUE = 0;